import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { mediaQueries } from "./breakpoints"
import { Container } from "./Container"
import { css } from "@emotion/react"
import { Section } from "./Section"
import CTA from "./CTA"
import Button from "./Button"
import moment from 'moment'
import Marked from "react-markdown"

const Program = styled("div")`
  margin-left: -1rem;
  margin-right: -1rem;
  ${mediaQueries.medium(
    css`
      margin: 0;
    `
  )}
`

const ProgramHeader = styled("div")`
  position: sticky;
  top: 0;
  z-index: 2;
  display: grid;
  grid-template-columns: 4rem 1fr 1fr;
  ${mediaQueries.medium(
    css`
      grid-template-columns: 14rem 1fr 1fr;
    `
  )}
`

const ProgramBody = styled("div")`
  display: grid;
  grid-template-columns: 4rem 1fr 1fr;
  ${mediaQueries.medium(
    css`
      grid-template-columns: 14rem 1fr 1fr;
    `
  )}
`

const ProgramDay = styled("div")`
  display: grid;
  grid-template-columns: 4rem 1fr 1fr;
  position: sticky;
  top: 0;
  z-index: 1;
  ${mediaQueries.medium(
    css`
      grid-template-columns: 14rem 1fr 1fr;
    `
  )}
`

const ProgramHeaderItem = styled("div")`
  padding: .75rem .5rem .5rem;
  text-align: center;
  background-color: var(--darkGray2);

  ${mediaQueries.medium(
    css`
      padding: .75rem 1rem .5rem;
    `
  )}

  ${mediaQueries.mediumDown(
    css`
      span {
        display: none;
      }
    `
  )}

  h2, p {
    margin-bottom: 0;
    ${mediaQueries.mediumDown(
      css`
        font-size: .875rem;
        line-height: 1.33;
      `
    )}
  }

  &:first-of-type {
    background-color: transparent;
    border-bottom-color: transparent;
  }

  &:last-of-type {
    border-left: 1px solid rgba(255, 255, 255, 0.08);
  }
`

const ProgramBodyItem = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: .75rem .5rem .5rem;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  background-color: #312E2E;
  border-left: 1px solid rgba(255, 255, 255, 0.08);

  ${mediaQueries.medium(
    css`
      padding: .75rem 1rem .5rem;
    `
  )}

  h3, p {
    margin-bottom: 0;
    ${mediaQueries.mediumDown(
      css`
        font-size: .875rem;
        line-height: 1.33;
      `
    )}
  }

  p:empty {
    display: none;
  }

  h3 {
    font-size: 1rem;
    color: var(--yellow);
  }

  &:first-of-type {
    justify-content: center;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
    background-color: transparent;
    border-left: 0;
    ${mediaQueries.mediumDown(
      css`
        font-size: .875rem;
        span {
          line-height: .5;
          text-align: center;
          width: 100%;
        }
      `
    )}
  }
`

const ProgramBodyItemUnited = styled(ProgramBodyItem)`
  grid-column: span 2;
`

const ProgramDayItem = styled("div")`
  padding: .75rem .5rem .5rem;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  background-color: #312E2E;
  border-left: 1px solid rgba(255, 255, 255, 0.08);

  ${mediaQueries.medium(
    css`
      padding: .75rem 1rem .5rem;
    `
  )}

  p {
    margin-bottom: 0;
    color: var(--yellow);
    ${mediaQueries.mediumDown(
      css`
        font-size: .875rem;
        line-height: 1.33;
      `
    )}
  }

  &:first-of-type {
    background-color: var(--darkGray2);
    border-left: 0;
  }
`

const ProgramDayItemUnited = styled(ProgramDayItem)`
  grid-column: span 2;
`


const NoEvent= styled("p")`
  margin-top: 2rem;
  text-align: center;
`

const SectionCTA = styled(Section)`
  text-align: center;
`

const ProgramSimple = styled("div")`
  text-align: center;

  h3 {
    font-size: var(--h4);
    margin-bottom: .5em;
    color: var(--yellow);
  }

  h4, p {
    font-size: var(--h5);
    margin-bottom: 1.5em;
    font-weight: 400;
    line-height: 1.5;
    strong {
      font-weight: 700;
    }
  }

  h4 + h4,
  p + p {
    margin-top: -1.5em;
  }
`

class EventPrograms extends React.Component {

  render() {
    const { data } = this.props
    const { edges: events } = data.allMarkdownRemark

    return (
      <>
        {events && events.length ? (
          <>
            {events &&
            events.map(({ node: event }) => (
              <div key={event.id}>
                {event.frontmatter.programActive === true && (
                  <>
                    {((event.frontmatter.programThursday) || (event.frontmatter.programFriday) || (event.frontmatter.programSaturday) || (event.frontmatter.programSunday)) && (
                      <Section id="program">
                        <Container>
                          <h2>PROGRAM</h2>
                          <Program>
                            <ProgramHeader>
                              <ProgramHeaderItem></ProgramHeaderItem>
                              <ProgramHeaderItem>
                                <p><strong>Misesova akademie <span>(MALI)</span></strong></p>
                              </ProgramHeaderItem>
                              <ProgramHeaderItem>
                                <p><strong>Misesův seminář <span>(MSLI)</span></strong></p>
                              </ProgramHeaderItem>
                            </ProgramHeader>

                            {event.frontmatter.programThursday &&
                            event.frontmatter.programThursday.length ? (
                              <>
                                <ProgramDay>
                                  <ProgramDayItem>
                                    <p><strong>Čtvrtek</strong></p>
                                  </ProgramDayItem>
                                  <ProgramDayItemUnited />
                                </ProgramDay>
                                {event.frontmatter.programThursday.map(program => (
                                  <>
                                    <ProgramBody key={program.id}>
                                      <ProgramBodyItem>
                                        {((program.programTimeStart) || (program.programTimeEnd)) && ((program.programTimeStart.lenght) || (program.programTimeEnd)) ? (
                                          <>
                                            {moment(program.programTimeStart).local().format(`H:mm`)}<span>–</span>⁠{moment(program.programTimeEnd).local().format(`H:mm`)}
                                          </>
                                        ) : null }
                                      </ProgramBodyItem>
                                      {program.programUnited === false ? (
                                        <>
                                          <ProgramBodyItem>
                                            <h3>{program.programEvent1speaker}</h3>
                                            <p>{program.programEvent1topic}</p>
                                          </ProgramBodyItem>
                                          <ProgramBodyItem>
                                            <h3>{program.programEvent2speaker}</h3>
                                            <p>{program.programEvent2topic}</p>
                                          </ProgramBodyItem>
                                        </>
                                      ) : (
                                        <ProgramBodyItemUnited>
                                          <h3>{program.programEvent1speaker}</h3>
                                          <p>{program.programEvent1topic}</p>
                                        </ProgramBodyItemUnited>
                                      )}
                                    </ProgramBody>
                                  </>
                                ))}
                              </>
                            ) : null}

                            {event.frontmatter.programFriday &&
                            event.frontmatter.programFriday.length ? (
                              <>
                                <ProgramDay>
                                  <ProgramDayItem>
                                    <p><strong>Pátek</strong></p>
                                  </ProgramDayItem>
                                  <ProgramDayItemUnited />
                                </ProgramDay>
                                {event.frontmatter.programFriday.map(program => (
                                  <>
                                    <ProgramBody key={program.id}>
                                      <ProgramBodyItem>
                                        {((program.programTimeStart) || (program.programTimeEnd)) && ((program.programTimeStart.lenght) || (program.programTimeEnd)) ? (
                                          <>
                                            {moment(program.programTimeStart).local().format(`H:mm`)}<span>–</span>⁠{moment(program.programTimeEnd).local().format(`H:mm`)}
                                          </>
                                        ) : null }
                                      </ProgramBodyItem>
                                      {program.programUnited === false ? (
                                        <>
                                          <ProgramBodyItem>
                                            <h3>{program.programEvent1speaker}</h3>
                                            <p>{program.programEvent1topic}</p>
                                          </ProgramBodyItem>
                                          <ProgramBodyItem>
                                            <h3>{program.programEvent2speaker}</h3>
                                            <p>{program.programEvent2topic}</p>
                                          </ProgramBodyItem>
                                        </>
                                      ) : (
                                        <ProgramBodyItemUnited>
                                          <h3>{program.programEvent1speaker}</h3>
                                          <p>{program.programEvent1topic}</p>
                                        </ProgramBodyItemUnited>
                                      )}

                                    </ProgramBody>
                                  </>
                                ))}
                              </>
                            ) : null}

                            {event.frontmatter.programSaturday &&
                            event.frontmatter.programSaturday.length ? (
                              <>
                                <ProgramDay>
                                  <ProgramDayItem>
                                    <p><strong>Sobota</strong></p>
                                  </ProgramDayItem>
                                  <ProgramDayItemUnited />
                                </ProgramDay>
                                {event.frontmatter.programSaturday.map(program => (
                                  <>
                                    <ProgramBody key={program.id}>
                                      <ProgramBodyItem>
                                        {((program.programTimeStart) || (program.programTimeEnd)) && ((program.programTimeStart.lenght) || (program.programTimeEnd)) ? (
                                          <>
                                            {moment(program.programTimeStart).local().format(`H:mm`)}<span>–</span>⁠{moment(program.programTimeEnd).local().format(`H:mm`)}
                                          </>
                                        ) : null }
                                      </ProgramBodyItem>
                                      {program.programUnited === false ? (
                                        <>
                                          <ProgramBodyItem>
                                            <h3>{program.programEvent1speaker}</h3>
                                            <p>{program.programEvent1topic}</p>
                                          </ProgramBodyItem>
                                          <ProgramBodyItem>
                                            <h3>{program.programEvent2speaker}</h3>
                                            <p>{program.programEvent2topic}</p>
                                          </ProgramBodyItem>
                                        </>
                                        
                                      ) : (
                                        <ProgramBodyItemUnited>
                                          <h3>{program.programEvent1speaker}</h3>
                                          <p>{program.programEvent1topic}</p>
                                        </ProgramBodyItemUnited>
                                      )}

                                    </ProgramBody>
                                  </>
                                ))}
                              </>
                            ) : null}

                            {event.frontmatter.programSunday &&
                            event.frontmatter.programSunday.length ? (
                              <>
                                <ProgramDay>
                                  <ProgramDayItem>
                                    <p><strong>Neděle</strong></p>
                                  </ProgramDayItem>
                                  <ProgramDayItemUnited />
                                </ProgramDay>
                                {event.frontmatter.programSunday.map(program => (
                                  <>
                                    <ProgramBody key={program.id}>
                                      <ProgramBodyItem>
                                        {((program.programTimeStart) || (program.programTimeEnd)) && ((program.programTimeStart.lenght) || (program.programTimeEnd)) ? (
                                          <>
                                            {moment(program.programTimeStart).local().format(`H:mm`)}<span>–</span>⁠{moment(program.programTimeEnd).local().format(`H:mm`)}
                                          </>
                                        ) : null }

                                      </ProgramBodyItem>
                                      {program.programUnited === false ? (
                                        <>
                                          <ProgramBodyItem>
                                            <h3>{program.programEvent1speaker}</h3>
                                            <p>{program.programEvent1topic}</p>
                                          </ProgramBodyItem>
                                          <ProgramBodyItem>
                                            <h3>{program.programEvent2speaker}</h3>
                                            <p>{program.programEvent2topic}</p>
                                          </ProgramBodyItem>
                                        </>
                                      ) : (
                                        <ProgramBodyItemUnited>
                                          <h3>{program.programEvent1speaker}</h3>
                                          <p>{program.programEvent1topic}</p>
                                        </ProgramBodyItemUnited>
                                      )}
                                    </ProgramBody>
                                  </>
                                ))}
                              </>
                            ) : null }
                          </Program>
                        </Container>
                      </Section>
                    )}

                    {event.frontmatter.program && (
                      <Section>
                        <Container narrow>
                          <Button
                            to={event.frontmatter.program.publicURL}
                          >
                            STÁHNOUT PROGRAM
                          </Button>
                        </Container>
                      </Section>
                    )}

                    <SectionCTA>
                      <Container>
                        <h2>TO JE PROGRAM, KTERÝ SI NEMŮŽETE NECHAT UJÍT</h2>
                        <CTA />
                      </Container>
                    </SectionCTA>
                  </>
                )}
                {event.frontmatter.programActive === false && (
                  <>
                    <Section id="program">
                      <Container>
                        <h2>PROGRAM</h2>
                        {event.frontmatter.programSimple &&
                        event.frontmatter.programSimple.length ? (
                          <ProgramSimple>
                            <Marked source={event.frontmatter.programSimple} />
                          </ProgramSimple>
                        ) :  <NoEvent>Na programu už pilně pracujeme</NoEvent> }
                      </Container>
                    </Section>

                    {event.frontmatter.program && (
                      <Section>
                        <Container narrow>
                          <Button
                            to={event.frontmatter.program.publicURL}
                          >
                            STÁHNOUT PROGRAM
                          </Button>
                        </Container>
                      </Section>
                    )}
                  </>
                )}
              </div>
            ))}
          </>
        ) : null }
      </>
    )
  }
}

EventPrograms.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query EventProgramQuery {
        allMarkdownRemark(
          limit: 1
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: {
              registrationStatus: { in: [ "preparation", "open", "close", "postponed" ]}
              templateKey: { eq: "event" }
            }
          }
        ) {
          edges {
            node {
              id
              html
              frontmatter {
                templateKey
                programActive
                programSimple
                program {
                  prettySize
                  publicURL
                }
                programThursday {
                  programTimeStart
                  programTimeEnd
                  programEvent1speaker
                  programEvent1topic
                  programEvent2speaker
                  programEvent2topic
                  programUnited
                }
                programFriday {
                  programTimeStart
                  programTimeEnd
                  programEvent1speaker
                  programEvent1topic
                  programEvent2speaker
                  programEvent2topic
                  programUnited
                }
                programSaturday {
                  programTimeStart
                  programTimeEnd
                  programEvent1speaker
                  programEvent1topic
                  programEvent2speaker
                  programEvent2topic
                  programUnited
                }
                programSunday {
                  programTimeStart
                  programTimeEnd
                  programEvent1speaker
                  programEvent1topic
                  programEvent2speaker
                  programEvent2topic
                  programUnited
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <EventPrograms data={data} count={count} />}
  />
)
